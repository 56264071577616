import {
  Options,
  RenderNode,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Document, INLINES, Inline } from '@contentful/rich-text-types';
import { TeliaCtaLink, TeliaImage, TeliaLink, TeliaVideoPlayer } from '@teliads/components/react';
import { Fragment, ReactNode } from 'react';

import BoardMemberCard from '../components/custom/board-member-card/BoardMemberCard';

import { CONTENTFUL_ASSET_TYPE } from './constants';
import { getDatablockInfo, getLinkTarget } from './utils';

import { urlLanguage } from 'apollo';
import { AssetLinks } from 'components/custom/content-block/models';
import DatablocksWidget from 'components/custom/datablock/DatablockFunctional';
import DocumentAssetContainer from 'components/custom/document-asset-container/DocumentAssetContainer';
import IframeEuroland from 'components/custom/euroland-iframe/EurolandIframe';
import ImageAssetContainer from 'components/custom/image-asset-container/ImageAssetContainer';
import TableRte from 'components/custom/table-rte/TableRte';
import AccordionContent from 'components/voca/accordion/AccordionContent';
import Heading from 'components/voca/heading/Heading';
import Paragraph from 'components/voca/paragraph/Paragraph';
import 'styles/_richtext.scss';

enum CTF_EMBEDDED_ENTRY {
  IMAGE = 'Image',
  PERSON = 'Person',
  VIDEO = 'Video',
  EMBEDMEDIA = 'EmbedMedia',
  CTA = 'CtaButton',
}

const hyperlinkOption: RenderNode = {
  [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
    <TeliaLink variant="text" href={node.data.uri} target={getLinkTarget(node.data.uri)}>
      {children}
    </TeliaLink>
  ),
};

const breakLineOption: Options = {
  renderText: (text: string): ReactNode => {
    return text.split('\n').reduce((children: ReactNode[], textSegment, index) => {
      return [...children, index > 0 && <br key={index} data-testid="richtext-br" />, textSegment];
    }, []);
  },
};

const options: Options = {
  ...breakLineOption,
  renderNode: {
    [BLOCKS.HEADING_3]: (_node: Block | Inline, children: ReactNode) => (
      <Heading tag="h3" variant="title-100">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (_node: Block | Inline, children: ReactNode) => (
      <Heading tag="h4" variant="display-25">
        {children}
      </Heading>
    ),
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => {
      return <Paragraph className="richtext-paragraph">{children}</Paragraph>;
    },
    ...hyperlinkOption,
  },
};

const plainTextOptions: Options = {
  ...breakLineOption,
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => <>{children}</>,
    ...hyperlinkOption,
  },
};

function mappedOptions(links: AssetLinks): Options {
  const assetMap = new Map();

  for (const asset of links.assets.hyperlink) {
    assetMap.set(asset.sys.id, asset);
  }

  const entryMap = new Map();

  for (const entry of links.entries.block) {
    entryMap.set(entry.sys.id, entry);
  }

  return {
    ...options,
    renderNode: {
      ...options.renderNode,
      [BLOCKS.TABLE]: (node: Block | Inline) => {
        const [theadNode, ...tableRows] = node.content;

        return (
          <TableRte>
            <thead>
              <tr className="table__table-row">
                {(theadNode as Block).content.map((block, idx) => (
                  <Fragment key={idx}>
                    {documentToReactComponents(block as Document, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
                      },
                    })}
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, index) => (
                <tr key={index} className="table__table-row">
                  {(row as Block).content.map((item, idx) => (
                    <Fragment key={idx}>
                      {documentToReactComponents(item as Document, {
                        ...breakLineOption,
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
                          ...hyperlinkOption,
                        },
                      })}
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </TableRte>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        const entry = entryMap.get(node.data.target.sys.id);

        switch (entry.__typename) {
          case CTF_EMBEDDED_ENTRY.IMAGE:
            return (
              <ImageAssetContainer
                imgSrc={entry.image.url}
                alt={entry.image.title}
                hyperlink={entry.hyperlink}
                description={entry.image.description}
              />
            );

          case CTF_EMBEDDED_ENTRY.VIDEO:
            return (
              <TeliaVideoPlayer
                controls
                autoplay
                loop
                muted
                slot="video"
                type="html"
                src={entry.video.url}
                poster={entry.video.url}
                title={entry.video.title}
                className="richtext-element"
                data-testid="richtext-telia-video"
              />
            );

          case CTF_EMBEDDED_ENTRY.EMBEDMEDIA:
            if (entry.url.includes('tools.euroland')) {
              return <IframeEuroland url={entry.url} title={entry.internalName} />;
            }

            if (entry.url.includes('widget.datablocks')) {
              const { widget, token } = getDatablockInfo(entry.url);

              if (widget?.includes('kpi-export')) {
                return (
                  <AccordionContent
                    title={entry.internalName}
                    url={entry.url}
                    contentType={CONTENTFUL_ASSET_TYPE.PDF}
                  />
                );
              }

              return (
                <DatablocksWidget
                  widget={widget}
                  locale={urlLanguage()}
                  token={token}
                  title={entry.internalName}
                />
              );
            }

            return (
              <iframe
                src={entry.url}
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                title={entry.internalName}
                className="richtext-element-iframe"
                loading="lazy"
              />
            );

          case CTF_EMBEDDED_ENTRY.PERSON:
            return <BoardMemberCard data={entry} />;

          case CTF_EMBEDDED_ENTRY.CTA:
            return (
              <div className="richtext-button-container">
                <TeliaCtaLink
                  variant="primary"
                  href={entry.linkTrigger}
                  target={getLinkTarget(entry.linkTrigger)}
                >
                  {entry.ctaText}
                </TeliaCtaLink>
              </div>
            );

          default:
            return undefined;
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node: Block | Inline) => {
        const asset = assetMap.get(node.data.target.sys.id);

        if (asset.contentType.startsWith('image')) {
          return (
            <TeliaImage
              src={asset.url}
              alt={asset.title}
              variant="scalable"
              className="richtext-element"
              data-testid="richtext-telia-image"
            />
          );
        }

        if (asset.contentType.startsWith('video')) {
          return (
            <TeliaVideoPlayer
              controls
              autoplay
              loop
              muted
              slot="video"
              type="html"
              src={asset.url}
              poster={asset.url}
              title={asset.title}
              className="richtext-element"
              data-testid="richtext-telia-video"
            />
          );
        }

        if (asset.contentType.startsWith('application')) {
          return <DocumentAssetContainer title={asset.title} url={asset.url} />;
        }
      },
    },
  };
}

export const renderRichText = (richText: Document, links?: AssetLinks): ReactNode => {
  return documentToReactComponents(richText, links ? mappedOptions(links) : options);
};

export const renderPlainTextFromRichText = (richText: Document) =>
  documentToReactComponents(richText, plainTextOptions);
